.CWContainer {
    grid-area: 4 / 1 / 26 / 25;
    background: rgb(192,192,192);
    background: linear-gradient(180deg, rgba(192,192,192,1) 0%, rgba(102,102,102,1) 100%);
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    border: .1vw solid #999999;
    border-left: 0px;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: white;
}

.CWTitle {
    grid-area: 1 / 1 / 3 / 31;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #888888;
    background-color: #3c4f65;
    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
    margin: .5vw;
    margin-bottom: 0;
    padding-left: 1vw;
    font-family: 'QS-Medium';
    font-size: 3vh;
}

.CWScreen {
    grid-area: 3 / 1 / 19 / 31;
    background-color: #2D2D2D;
    border-bottom-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
    margin-left: .5vw;
    margin-right: .5vw;
    border: .1vw solid #333333;
    color: #ffffff;
    overflow-y: auto;
    overflow-x: auto;
    font-family: 'SourceCodePro';
    font-size: 1.75vh;
    font-weight: 400;
}

/*
 *  Scrollbar Styling
 */
::-webkit-scrollbar
{
    width: 1vw;
    height: 1vw;
}
::-webkit-scrollbar-track
{
    background: gray;
    box-shadow: inset 0 0 .5vw rgba(0,0,0,.3);
    border-radius: 1vw;
}
::-webkit-scrollbar-thumb
{
    border-radius: 1vw;
    box-shadow: inset 0 0 .5vw rgba(0,0,0,.3);
    background-color: #36A1F2;
    border: .1vw solid #333333;
}

.CWDescription {
    padding: 1vw;
    font-family: 'QS-Regular';
}
