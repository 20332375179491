.DesktopContainer {
    /*grid-area: 1 / 1 / 11 / 17;*/
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(50, 1fr);
    grid-template-rows: repeat(26, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: none;
}
.ActiveTab {
    grid-area: 1 / 23 / 3 / 29;
    color: white;
    font-size: 3vmin;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TestSquare {
    background-color: #ff0000;
    
    color: #ffffff;
}
.NavLogo {
    grid-area: 1 / 1 / 3 / 26; 
    font-family: 'QS-Bold';
    border-bottom: 1px solid #888888;
    background-color: #3c4f65;
    color: white;
    font-size: 3vmin;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}
.NavLogoText {
    padding-left: 3vmin;
}
.NavLogoText a{
    color: white;
}
.NavLogo img {
    padding-left: 3vmin;
    height: 5vmin;
    filter: invert(100%) sepia(59%) saturate(2%) hue-rotate(77deg) brightness(113%) contrast(100%);
}
.NavMenu {
    grid-area: 1 / 26 / 3 / 51;
    font-family: 'QS-Medium';
    border-bottom: 1px solid #888;
    background-color: #3c4f65;
    color: white;
    font-size: 2.5vmin;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
}
.MenuItem {
    padding-right: 5vmin;
}
.Window {
    display: flex;
    opacity: .8;
    align-items: center;
    justify-content: center;
    font-size: 2.5vmin;
    border: 2px solid black;
    border-radius: 2vmin;
    background-color: #dddddd;
    z-index: 1;
    box-shadow: 2px 2px 5px black;
}
.WindowLeft {
    grid-area: 17 / 2 / 25 / 21;
}
.WindowRight {
    grid-area: 17 / 30 / 25 / 49;
}

.HeroText {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.HeroTextTop {
    grid-area: 8 / 18 / 12 / 40;
    font-size: 10vmin;
    font-family: 'QS-Bold';
}
.HeroTextBottom {
    grid-area: 12 / 18 / 14 / 40;
    font-size: 5vmin;
    font-family: 'QS-Regular';
}
.HeroImage {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 8 / 12 / 14 / 18;
    border-radius: 50%;
    overflow: hidden;
}
.HeroImage img {
    width: 100%;
    height: auto;
}

.Logo {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Logo:hover {
    cursor: pointer;
}
.Logo img{
    width: 15vmin;
    height: auto;
}
.LogoText {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'QS-Regular';
    font-size: 3vmin;
}
.LogoPosition1 {
    grid-area: 17 / 3 / 23 / 9;
}
.LogoText1 {
    grid-area: 22 / 3 / 24 / 9;
}
.LogoPosition2 {
    grid-area: 17 / 11 / 23 / 17;
}
.LogoText2 {
    grid-area: 22 / 11 / 24 / 17;
}
.LogoPosition3 {
    grid-area: 17 / 19 / 23 / 25;
}
.LogoText3 {
    grid-area: 22 / 19 / 24 / 25;
}
.LogoPosition4 {
    grid-area: 17 / 27 / 23 / 33;
}
.LogoText4 {
    grid-area: 22 / 27 / 24 / 33;
}
.LogoPosition5 {
    grid-area: 17 / 35 / 23 / 41; 
}
.LogoText5 {
    grid-area: 22 / 35 / 24 / 41;
}
.LogoPosition6 {
    grid-area: 17 / 43 / 23 / 49;
}
.LogoText6 {
    grid-area: 22 / 43 / 24 / 49;
}

.PageLogo {
    grid-area: 5 / 15 / 11 / 21;
    z-index: 1;
}
.PageLogo img {
    width: 100%;
    height: auto;
}
.PageTitle {
    grid-area: 5 / 21 / 11 / 37;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'QS-Regular';
    font-size: 3vmin;
}

.glow {
    animation: glow 5s ease-in-out infinite alternate;
}

@keyframes glow {
    0%,
  100% {
    text-shadow: 0 0 .5vw #1041FF, 0 0 1vw #1041FF, 0 0 2vw #1041FF, 0 0 2vw #1041FF, 0 0 .4vw #8BFDFE, .25vw .25vw .1vw #147280;
    color: #ccffff;
  }
  50% {
    text-shadow: 0 0 .25vw #082180, 0 0 .5vw #082180, 0 0 1vw #082180, 0 0 1vw #082180, 0 0 .2vw #082180, .15vw .15vw .1vw #0A3940;
    color: #00ffff;
  }
}