/* CONTAINER */
.MBContainer {
    grid-area: 4 / 27 / 26 / 51;
    background: rgb(192, 192, 192);
    background: linear-gradient(180deg, rgba(192, 192, 192, 1) 0%, rgba(102, 102, 102, 1) 100%);
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
    border: .1vw solid #999999;
    border-right: 0px;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 2vh;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #333333;
    opacity: 1;
    /* Firefox */
}

.MBScreenShadow {
    grid-area: 1 / 1 / 19 / 31;
    box-shadow: inset 0 0 .1vw .2vw rgba(0, 0, 0, 0.25);
    margin: .5vw;
    margin-bottom: 0;
    border-radius: 1vw;
}

/* TITLE SECTION */
.MBTitle {
    grid-area: 1 / 1 / 3 / 31;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #888888;
    background-color: #3c4f65;
    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
    margin: .5vw;
    margin-bottom: 0;
    padding-left: 1vw;
}

.LeewayLogo {
    height: 3vh;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(345deg) brightness(102%) contrast(103%);
}

.LeewayLogoText {
    padding-left: .5vw;
    height: 3vh;
    font-family: 'CF-Bold';
    font-size: 3vh;
    color: white;
}

/* SCREEN SECTION */
.MBScreen {
    grid-area: 3 / 1 / 19 / 31;
    background-color:#2D2D2D;
    border-bottom-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
    margin-left: .5vw;
    margin-right: .5vw;
    border: .1vw solid #666666;
    color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 4vh;
}

/* Scrollbar Styling */
.MBScreen::-webkit-scrollbar {
    width: 1vw;
    height: 1vw;
}

.MBScreen::-webkit-scrollbar-track {
    background: gray;
    box-shadow: inset 0 0 .5vw rgba(0, 0, 0, .3);
    border-radius: 1vw;
}

.MBScreen::-webkit-scrollbar-thumb {
    border-radius: 1vw;
    box-shadow: inset 0 0 .5vw rgba(0, 0, 0, .3);
    background-color: #36A1F2;
    border: .1vw solid #333333;
}

.MessageOuter {
    padding: .5vw;
    margin-left: .5vw;
    width: 100%;
    display: flex;
}

.MessageOuter:hover {
    background: #444444;
}

.MessageInner {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    width: 100%;
}

.MessageAvatar {
    height: 4vh;
    width: 4vh;
}

.MessageAvatar img {
    height: 100%;
    filter: invert(97%) sepia(99%) saturate(0%) hue-rotate(239deg) brightness(117%) contrast(100%);
}

.MessageHeader {

    display: flex;
    align-items: center;
}

.MessageHeaderUser {
    font-family: 'QS-Bold';
    font-size: 2.25vh;
}

.MessageHeaderTime {
    font-family: 'QS-Light';
    font-size: 1.5vh;
    margin-left: .5vw;
    color: #cccccc;
}

.MessageBody {
    font-family: 'QS-Regular';
    max-width: 95%;
}

.DateLineWrapper {
    height: 6vh;
    width: 100%;
}

.DateLineItem {
    height: 3.5vh;
    width: 30%;
    font-size: 1.5vh;
    border: .2vh solid #999999;
    border-radius: 1vw;
}

/* CONTROLS SECTION */

.MBControls {
    grid-area: 19 / 1 / 21 / 31;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
}

.MBControlsDefault {
    all: unset;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
    border: .15vw solid #444444;
    height: 5vh;
    color: #000000;
}

.MBNameIcon {
    grid-area: 1 / 9 / 3 / 11;
    place-self: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
    border: .15vw solid #444444;
    border-right: 0vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vh;
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
}

.MBNameIcon img {
    height: 4vh;
}

.MBName {
    grid-area: 1 / 11 / 3 / 23;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MBName input {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    padding-left: 1vw;
    width: 100%;
    height: 5vh;
}

.MBEmoji {
    grid-area: 1 / 3 / 3 / 5;
    display: flex;
    align-items: center;
}

.MBEmoji button {
    width: 100%;
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
    border-right: 0vw;
}

.MBEmoji button:hover {
    cursor: pointer;
    background: #cccccc;
}

.MBEmoji button:focus {
    background: #B200FD;
}

.MBEmoji img {
    height: 3vh;
    filter: drop-shadow(0px 1px .5px rgba(0, 0, 0, .5));
}

.MBPicker {
    grid-area: 1 / 3 / 3 / 15;
    z-index: 2;
    display: flex;
    align-items: flex-end;
}

.MBLoading {
    grid-area: 1 / 3 / 3 / 15;
    z-index: 2;
    height: 320px;
    width: 280px;
    background-color: #cccccc;
    color: #333333;
    border: 2px solid #333333;
}

.MBMessage {
    grid-area: 1 / 5 / 3 / 27;
    display: flex;
    align-items: center;
}

.MBMessage input {
    border-right: 0px;
    padding-left: .5vw;
    width: 100%;
}

.MBSend {
    grid-area: 1 / 27 / 3 / 29;
    display: flex;
    align-items: center;
}

.MBSend button {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    width: 100%;
}

.MBSend button:hover {
    cursor: pointer;
    background: #cccccc;
}

.MBSend button:focus {
    background: #B200FD;
}

.MBSend img {
    height: 3vh;
    filter: drop-shadow(0px 1px .5px rgba(0, 0, 0, .5));
}

