body {
  margin: 0;
  background-color: #33313b;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'SourceCodePro';
    src: url('./fonts/SourceCodePro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'QS-Regular';
    src: url('./fonts/Quicksand-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'QS-Bold';
    src: url('./fonts/Quicksand-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'QS-Light';
    src: url('./fonts/Quicksand-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'QS-Medium';
    src: url('./fonts/Quicksand-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'QS-SemiBold';
    src: url('./fonts/Quicksand-SemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CF-Regular';
    src: url('./fonts/Comfortaa-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CF-Bold';
    src: url('./fonts/Comfortaa-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CF-Light';
    src: url('./fonts/Comfortaa-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CF-Medium';
    src: url('./fonts/Comfortaa-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CF-SemiBold';
    src: url('./fonts/Comfortaa-SemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
