.SpinnerContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    background: rgba(0, 0, 0, 0.5)

}

.SpinnerWindow {
    position: absolute;
    top: 32.5vh;
    left: 42.5vw;
    opacity: 1;
    width: 15vw;
    height: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(232, 232, 232, 1);
    border-radius: 33%;
    border: 1px solid #888888;
}

.SpinnerLoadingText {
    font-size: 4vh;
}