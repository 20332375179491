.App {
    height: 100vh;
    width: 100vw;
    
    overflow: hidden;
    display: grid;
    grid-template-areas: 
    'X1Y1	X1Y2	X1Y3	X1Y4	X1Y5	X1Y6	X1Y7	X1Y8	X1Y9	X1Y10	X1Y11	X1Y12	X1Y13	X1Y14	X1Y15	X1Y16'
    'X2Y1	X2Y2	X2Y3	X2Y4	X2Y5	X2Y6	X2Y7	X2Y8	X2Y9	X2Y10	X2Y11	X2Y12	X2Y13	X2Y14	X2Y15	X2Y16'
    'X3Y1	X3Y2	X3Y3	X3Y4	X3Y5	X3Y6	X3Y7	X3Y8	X3Y9	X3Y10	X3Y11	X3Y12	X3Y13	X3Y14	X3Y15	X3Y16'
    'X4Y1	X4Y2	X4Y3	X4Y4	X4Y5	X4Y6	X4Y7	X4Y8	X4Y9	X4Y10	X4Y11	X4Y12	X4Y13	X4Y14	X4Y15	X4Y16'
    'X5Y1	X5Y2	X5Y3	X5Y4	X5Y5	X5Y6	X5Y7	X5Y8	X5Y9	X5Y10	X5Y11	X5Y12	X5Y13	X5Y14	X5Y15	X5Y16'
    'X6Y1	X6Y2	X6Y3	X6Y4	X6Y5	X6Y6	X6Y7	X6Y8	X6Y9	X6Y10	X6Y11	X6Y12	X6Y13	X6Y14	X6Y15	X6Y16'
    'X7Y1	X7Y2	X7Y3	X7Y4	X7Y5	X7Y6	X7Y7	X7Y8	X7Y9	X7Y10	X7Y11	X7Y12	X7Y13	X7Y14	X7Y15	X7Y16'
    'X8Y1	X8Y2	X8Y3	X8Y4	X8Y5	X8Y6	X8Y7	X8Y8	X8Y9	X8Y10	X8Y11	X8Y12	X8Y13	X8Y14	X8Y15	X8Y16'
    'X9Y1	X9Y2	X9Y3	X9Y4	X9Y5	X9Y6	X9Y7	X9Y8	X9Y9	X9Y10	X9Y11	X9Y12	X9Y13	X9Y14	X9Y15	X9Y16'
    'X10Y1	X10Y2	X10Y3	X10Y4	X10Y5	X10Y6	X10Y7	X10Y8	X10Y9	X10Y10	X10Y11	X10Y12	X10Y13	X10Y14	X10Y15	X10Y16';
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    z-index: -1; 
}

.Mobile {
    height: 100vh;
    width: 100vw;
}
.FlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}